import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Marquee from "react-fast-marquee"
import Layout from "../components/Layout"
import Panels from "../components/Panels"
import ProjectItem from "../components/ProjectItem"
import SEO from "../components/seo"
import "./index.scss"
import Newsletter from "../components/Newsletter"

const transition = { duration: 8, ease: [0.6, -0.05, 0.01, 0.9] }

const parent = {
  animate: {
    transition: {
      staggerChildren: 1,
      delayChildren: 1,
    },
  },
}

const Accueil = ({ data, pageContext, prevLocation }) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
  }, [])

  const checkMobile = isMobile < 768

  return (
    <>
      <SEO
        title={
          pageContext.lang === "en"
            ? data && data.EN && data.EN.title && data.EN.title
            : data && data.FR && data.FR.titre && data.FR.titre
        }
        description={
          data &&
          data.sanityPage &&
          data.sanityPage.titre &&
          data.sanityPage.titre
        }
        image={
          data &&
          data.allSanityPage &&
          data.allSanityPage.edges &&
          data.allSanityPage.edges[0] &&
          data.allSanityPage.edges[0].node.mainImage.asset.url
        }
      />

      <Layout lang="fr">
        <div className="wrapper">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { delay: 0.2 } }}
            exit={{ opacity: 0, transition: { delay: 0.2 } }}
          >
            <Link to="/en">
              <div className="lang-switcher">En</div>
            </Link>
            <motion.ul
              /*              variants={checkMobile && parent} */
              initial="initial"
              animate="animate"
              exit="exit"
              className="list-image-container"
            >
              {data.allSanityPage &&
                data.allSanityPage.edges.map((project, index) => (
                  <ProjectItem
                    key={index}
                    project={project}
                    itemIndex={index}
                  />
                ))}
            </motion.ul>

            {!checkMobile && (
              <div className="marquee-infolettre-desktop">
                <Marquee
                  gradient={false}
                  speed={checkMobile ? 1 : 50}
                  direction={checkMobile ? "right" : "left"}
                >
                  <Newsletter />
                </Marquee>
              </div>
            )}
          </motion.div>
        </div>
      </Layout>
    </>
  )
}

export default Accueil

export const query = graphql`
  {
    allSanityPage(sort: { order: ASC, fields: ordre }) {
      edges {
        node {
          slug {
            current
          }
          titre
          title
          ordre
          message_fr
          message_url_fr
          liens_ext
          liens_url

          credits
          mainImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
