const easing = {
  linear: t => t,
  easeInQuad: (t, b, c, d) => {
    return c * (t /= d) * t + b
  },
  easeOutQuad: t => t * (2 - t),
  easeInOutQuad: (t, b, c, d) => {
    if ((t /= d / 2) < 1) {
      return (c / 2) * t * t + b
    } else {
      return (-c / 2) * (--t * (t - 2) - 1) + b
    }
  },
  easeInCubic: (t, b, c, d) => {
    return c * (t /= d) * t * t + b
  },
  easeOutCubic: (t, b, c, d) => {
    return c * ((t = t / d - 1) * t * t + 1) + b
  },
  easeInOutCubic: t =>
    t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,
}

function getValue(start, end, elapsed, duration, easeMethod) {
  if (elapsed > duration) return end

  return start + (end - start) * easing[easeMethod](elapsed / duration)
}

export default function animate({
  fromValue,
  toValue,
  onUpdate,
  onComplete,
  duration = 600,
  easeMethod = "linear",
}) {
  const startTime = performance.now()

  const tick = () => {
    const elapsed = performance.now() - startTime

    if (window !== "undefined") {
      window.requestAnimationFrame(() => {
        return onUpdate(
          getValue(fromValue, toValue, elapsed, duration, easeMethod),

          //callback
          elapsed <= duration ? tick : onComplete
        )
      })
    }
  }
  tick()
}
