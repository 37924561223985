import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useReducer, useRef, useState } from "react"
import Image from "../ProjectItem/Image"
import Title from "../ProjectItem/Title"
import animate from "./animate"
import "./style.scss"

const initialState = {
  opacity: 0,
  parallaxPos: { x: 0, y: 120 },
  scale: 0.8,
  rotationPosition: 10,
  active: false,
}

function reducer(state, action) {
  switch (action.type) {
    case "MOUSE/ENTER": {
      return { ...state, active: true }
    }
    case "MOUSE/LEAVE": {
      return { ...state, active: false }
    }
    case "CHANGE/OPACITY": {
      return { ...state, opacity: action.payload }
    }
    case "MOUSE/COORDINATES": {
      return { ...state, parallaxPos: action.payload }
    }
    case "CHANGE/ROTATION": {
      return { ...state, rotationPosition: action.payload }
    }
    case "CHANGE/SCALE": {
      return { ...state, scale: action.payload }
    }
    default:
      throw Error()
  }
}

const ProjectItem = ({ project, itemIndex, lang }) => {
  const listItem = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)
  const easeMethod = "easeInOutCubic"

  const [infoState, setInfoState] = useState(false)

  const parallax = event => {
    if (window !== "undefined") {
      const speed = -35
      const x = (window.innerWidth - event.pageX * speed) / 100
      const y = (window.innerHeight - event.pageY * speed) / 100

      dispatch({ type: "MOUSE/COORDINATES", payload: { x, y } })
    }
  }

  const handleOpacity = (initialOpacity, newOpacity, duration) => {
    animate({
      fromValue: initialOpacity,
      toValue: newOpacity,
      onUpdate: (newOpacity, callback) => {
        dispatch({ type: "CHANGE/OPACITY", payload: newOpacity })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const handleScale = (initialScale, newScale, duration) => {
    animate({
      fromValue: initialScale,
      toValue: newScale,
      onUpdate: (newScale, callback) => {
        dispatch({ type: "CHANGE/SCALE", payload: newScale })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const handleRotation = (currentRotation, duration) => {
    // Random between -15 and 15
    const newRotation = Math.random() * 15 * Math.round(Math.random()) ? 1 : -1
    animate({
      fromValue: currentRotation,
      toValue: newRotation,
      onUpdate: (newRotation, callback) => {
        dispatch({ type: "CHANGE/ROTATION", payload: newRotation })
        callback()
      },
      onComplete: () => {},
      duration: duration,
      easeMethod: easeMethod,
    })
  }

  const durationImg = 1000

  const handleMouseEnter = () => {
    handleScale(0.8, 1, durationImg)
    handleOpacity(0, 1, durationImg)
    handleRotation(state.rotationPosition, 500)
    listItem.current.addEventListener("mousemove", parallax)
    dispatch({ type: "MOUSE/ENTER" })
    setInfoState(true)
  }

  const handleMouseLeave = () => {
    handleOpacity(0, 0, durationImg)
    handleScale(1, initialState.scale, durationImg)
    handleRotation(state.rotationPosition, 500)
    listItem.current.removeEventListener("mousemove", parallax)
    dispatch({ type: "CHANGE/OPACITY", payload: 0 })
    dispatch({ type: "MOUSE/COORDINATES", payload: 0 })
    dispatch({ type: "MOUSE/LEAVE" })
    setInfoState(false)
  }

  const transition = { duration: 1, ease: "easeInOut" }

  const slideUp = {
    initial: { y: -20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    exit: { y: -20 },
  }

  return (
    <>
      <motion.li
        className="project-item-container"
        ref={listItem}
        variants={slideUp}
        transition={transition}
      >
         
        {project.node.liens_ext && project.node.liens_ext === true ? (
          <a
            href={project.node.liens_url && project.node.liens_url}
            alt="boutique"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Title
              title={lang === "en" ? project.node.title : project.node.titre}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </a>
        ) : (
          <Link
            to={
              lang === "en"
                ? `/en/${project.node.slug_en.current}`
                : `/${project.node.slug.current}`
            }
          >
            <Title
              title={lang === "en" ? project.node.title : project.node.titre}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </Link>
        )}
        {project.node.liens_ext && project.node.liens_ext === true ? (
          ""
        ) : (
          <Image
            url={project.node.mainImage && project.node.mainImage.asset.url}
            opacity={state.opacity}
            parallaxPos={state.parallaxPos}
            scale={state.scale}
            handleRotation={state.rotationPosition}
            credits={project.node.credits && project.node.credits}
          />
        )}
        <div className={`info-block ${infoState && "as-active"}`}>
          <p className="info-block-header">
            <span></span>
          </p>

          <p>
            <span>
              {(project.node.message_fr && project.node.message_fr) ||
                (project.node.message_en && project.node.message_en)}
            </span>
          </p>
        </div>
      </motion.li>
    </>
  )
}

export default ProjectItem
