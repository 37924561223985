import React, { useEffect, useState } from "react"

const Image = ({
  url,
  opacity,
  parallaxPos,
  scale,
  handleRotation,
  credits,
}) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    function updateSize() {
      setIsMobile(window.innerWidth)
    }
    window.addEventListener("resize", updateSize)
    updateSize()
  }, [])

  const checkMobile = isMobile < 768
  return (
    <>
      {!checkMobile && (
        <img
          src={url}
          style={{
            opacity,
            transform: `translate3d(${parallaxPos.x}px,${parallaxPos.y}px, 0px) rotate(${handleRotation}deg) scale(${scale}) `,
            maxWidth: "1600px",
            objectFit: "contain",
          }}
        />
      )}
    </>
  )
}

export default Image
